import React from 'react';
import Img from 'gatsby-image';
import Container from '../Container';
import { ButtonLink } from '../Link';
import { useGaEvent } from '../../hooks/hooks.ga-events';

const PublicationHeader = ({
  coverImage, topic, title, downloadLink, file
}) => {
  const handleGaEvent = () => {
    useGaEvent(
      'Downloads',
      `${file.mimeType.split('/')[1]}`,
      `${downloadLink}`,
      undefined,
      { downloadFileName: `${file.filename}` }
    );
  };
  return (
    <Container className="header-publication">
      <div className="header-publication__wrapper">
        <figure className="header-publication__image-container">
          {
            coverImage && (
              <Img
                fluid={coverImage.fluid}
                className="header-publication__image"
                alt={coverImage.alt}
              />
            )
          }
        </figure>
        <div className="header-publication__text-container">
          {topic && <span className="header-publication__category label-small">{topic.name}</span>}
          {title && <h1 className="header-publication__title heading-lg">{title}</h1>}
          {
            downloadLink && (
              <ButtonLink
                className="header-publication__btn"
                kind="primary"
                label="Download"
                color="white"
                url={downloadLink}
                onClick={handleGaEvent}
              />
            )
          }
        </div>
      </div>
    </Container>
  );
};

export default PublicationHeader;
