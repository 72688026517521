/* eslint-disable */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../containers/layout';
import PublicationHeader from '../components/PublicationHeader';
import Accordion from '../components/Accordion';
import AccordionRichText from '../components/AccordionRichText';
import ListCardPublication from '../components/ListCardsPublication';
import Feed from '../components/Feed';
import VisualComposer from '../components/VisualComposer';


const SinglePublication = ({ data, pageContext }) => {
  const { publication } = { publication: data.datoCmsPubblicazione };
  const { publications } = { publications: data.allDatoCmsPubblicazione };
  const { title, coverImage, topic, file } = publication; // PublicationHeader
  const { description, info, author } = publication; // Accordions
  const { visualComposer, seoMetaTags } = publication; // Accordions
  const { slugPublicationArchive }  = { slugPublicationArchive: data.datoCmsPublicationArchive.slug };

  const { relatedPublication } = { relatedPublication: publication.publicationsRelated[0] };
  const { filteredRelatedPublications } = { filteredRelatedPublications: publications.nodes };
  const showRelatedCustom = relatedPublication && (relatedPublication.list.length > 0);
  const downloadLink = file ? file.url : ''; // PublicationHeader
  const { visualComposerOnlyBannerText } = { visualComposerOnlyBannerText: data.datoCmsPublicationBanner.visualComposer }

  return (
    <Layout
      seoMetaTags={seoMetaTags}
      pathname={pageContext.pathname}
      communicationBar={pageContext.communicationBar}
      pageType="Publication Page"
      canonical={pageContext.canonical}
    >
      <PublicationHeader
        title={title}
        topic={topic}
        coverImage={coverImage}
        downloadLink={downloadLink}
        file={file}
      />

      <div className="publication__accordion">
        {info && (
        <Accordion title="Informazioni" isActive>
          <AccordionRichText content={info} />
        </Accordion>
        )}
        {description && (
          <Accordion title="Descrizione">
            <AccordionRichText content={description} />
          </Accordion>
        )}
        {author && (
          <Accordion title="Autore">
            <AccordionRichText content={author} />
          </Accordion>
        )}
      </div>
      {
        visualComposer && visualComposer.length > 0 ? (
          <VisualComposer
            components={visualComposer}
          />
        ) : (
          <VisualComposer
            components={visualComposerOnlyBannerText}
          />
        )
      }

      {((relatedPublication && relatedPublication.list.length > 0) || filteredRelatedPublications.length > 0) && (
          <>
            {showRelatedCustom ? (
              <Feed
                title={relatedPublication.title}
                labelLink={relatedPublication.labelLink}
                url={slugPublicationArchive}
                labelLink="Vedi Tutte"
                isPublication
              >
                <ListCardPublication
                  cards={relatedPublication.list}
                  isFeed
                />
              </Feed>
            ) : (
              <Feed
                title="pubblicazioni"
                labelLink="Vedi Tutte"
                url={slugPublicationArchive}
                isPublication
              >
                <ListCardPublication
                  cards={filteredRelatedPublications}
                  isFeed
                  url={slugPublicationArchive}
                />
              </Feed>
            )}
          </>
        )
      }

    </Layout>
  );
};

export const POST_QUERY = graphql`
  query publicationTemplate($id: String!, $topicId: String!) {
    allDatoCmsPubblicazione (
      limit: 3,
      filter: {
        topic: {
          id: {
            eq: $topicId
          }
        }
        id: {
          ne: $id
        }
      },
      sort: {fields: publicationDate, order: DESC}
    ) {
      nodes {
        id
        title
        slug
        topic {
          slug
          name
        }
        coverImage {
          alt
          fluid(
            imgixParams: {fm: "jpg", auto: "compress", dpr: 2},
          ) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    datoCmsPublicationArchive {
      slug
    }
    datoCmsPubblicazione(id: {eq: $id}) {
      id
      title
      slug
      description
      info
      author
      file {
        url
        filename
        mimeType
      }
      topic {
        slug
        name
      }
      seoMetaTags {
        tags
      }
      coverImage {
        alt
        fluid(
          imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
          sizes: "(min-width: 48em) 400px, 100vw"
        ) {
          ...GatsbyDatoCmsFluid
        }
      }
      visualComposer {
        ...BannerFullImage
        ...BannerText
        ...CenteredNumbers
        ...Numbers
        ...Spacing
      }
      publicationsRelated {
        labelLink
        title
        list {
          title
          slug
          topic {
            id
            name
            slug
          }
          id
          coverImage {
            fluid(
              imgixParams: {fm: "jpg", auto: "compress", dpr: 3},
              sizes: "(min-width: 48em) 400px, 100vw"
            ) {
              ...GatsbyDatoCmsFluid
            }
            alt
          }
        }
      }
    }
    datoCmsPublicationBanner {
      visualComposer {
        ...BannerText
      }
    }
  }
`;

export default SinglePublication;
